<template>
  <div class="container">
    <div class="card border-0">
      <div class="card-body">
        <!--
        <div class="row mb-4 text-end">
          <div class="col">
            <a
                    href="/forms/new"
                    class="btn btn-outline-primary btn-sm"
            >Create Custom Form</a>
          </div>
        </div>
        -->
        <div class="row">
          <div class="col">
            <div class="row mb-3">
              <div class="col-8 mx-auto">
                <h4>Forms</h4>
                <p class="alert alert-primary">
                  <i class="far fa-info-circle"></i>
                  These forms are shared with us from our community of
                  Practitioners
                </p>
                <div class="custom-control custom-switch mb-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="showPublicSwitch"
                    v-model="showPublic"
                    :checked="showPublic"
                  />
                  <label class="custom-control-label" for="showPublicSwitch"
                    >Show Public Form Templates</label
                  >
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <button
                      class="btn btn-sm me-2"
                      :class="
                        categoryFilter === category
                          ? 'btn-primary'
                          : 'btn-outline-primary'
                      "
                      v-for="category in categories"
                      @click="filterCategory(category)"
                    >
                      {{ category.substring(0, 23)
                      }}<span v-if="category.length >= 23">...</span>
                    </button>
                  </div>
                </div>
                <div class="card bg-light mb-3" v-for="form in currentForms">
                  <div class="card-body">
                    <h5 class="mb-3">{{ form.title }}</h5>
                    <span>{{ form.category }}</span>
                    <a
                      :href="'/forms/' + form.id + '/preview'"
                      target="_blank"
                      class="btn btn-outline-primary me-2 btn-sm float-end"
                    >
                      <i class="far fa-eye me-1"></i>Preview
                    </a>

                    <p class="fw-bold font-italic mb-0">
                      <small>Form ID: #{{ form.id }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forms: [],
      publicForms: [],
      categoryFilter: null,
      showPublic: true,
      categories: [
        "Adult Health Intake Questionnaire",
        "Child Health Intake Questionnaire",
        "Food Diary",
        "Other",
      ],
    };
  },
  methods: {
    async fetchForms() {
      try {
        const response = await this.$axios.get(process.env.VUE_APP_API_URL + "/forms/api/public");
        this.forms = response.data.filter((form) => form.status == "published");
      } catch (e) {
        console.error(`There was a problem fetching client forms.. ${e}`);
      }
    },
    filterCategory(category) {
      this.categoryFilter = this.categoryFilter === category ? null : category;
    },
  },
  computed: {
    currentForms() {
      var ret = this.forms;
      const vm = this;
      if (this.categoryFilter) {
        ret = ret.filter(function (el) {
          return el.category === vm.categoryFilter;
        });
      }
      if (!this.showPublic) {
        ret = ret.filter(function (el) {
          return !el.is_public;
        });
      }
      return ret;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  created() {
    this.fetchForms();
  },
};
</script>

<style>
.custom-row-styles {
  cursor: pointer;
}
</style>
